import axios from "axios";
import StatisticLabelOperationsDTO from "@/models/StatisticLabelOperationsDTO";
import StatisticLabelDTO from "@/models/StatisticLabelDTO";

export const statistic = {
    labelsOperations: async (requestDTO: any = {}): Promise<StatisticLabelOperationsDTO[]> => {
        let resp = await axios({
            url: `/statistic/labels-operations`,
            data: {
                ...requestDTO
            },
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            method: 'POST',
        });

        return resp.data as StatisticLabelOperationsDTO[]
    },

    labels: async (): Promise<StatisticLabelDTO[]> => {
        let resp = await axios({
            url: `/statistic/labels`,
            method: 'GET',
        });

        return resp.data as StatisticLabelDTO[]
    },
}